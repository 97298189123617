import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ShippInfoPage = () => {
  return (
    <Layout>
      <SEO title="Dostawa" />

      <div className="regulaminContent proza">
        <h1>Realizacja zamówień</h1>
        <h2>Czas realizacji zamówienia</h2>
        <p>
          Czas realizacji zamówienia: od 2 do 5 dni roboczych w zależności od
          stanu magazynowego.
        </p>
        <h2>Przesyłka</h2>
        <p>Plakaty sprzedaję bez oprawy, w tekturowych tubach. </p>
        <p>Korzystam z usług firm kurierskich. </p>
        <p>Koszt przesyłki plakatu na terenie Polski – 15zł.</p>
        <p>
          Koszt przesyłki zagranicznej wyceniam indywidualnie, zgodnie z taryfą
          Poczty Polskiej.
        </p>
        <h2>Odbiór osobisty</h2>
        <p>
          Możliwość odbioru osobistego, po uprzednim umówieniu, w pracowni Kanon
          Architekci (Łódź, ul. Gdańska 80, wejście A, drugie piętro). Odbiór
          możliwy w dni robocze w godzinach 9.00-17.00.
        </p>
        <h2>Oprawa</h2>
        <p>Plakaty sprzedaję bez oprawy, w tekturowych tubach. </p>
        <p>
          Uwaga! Zalecam ostrożne wypakowywanie plakatów, przy użyciu
          materiałowych rękawiczek. Tak, zimowe są ok! :) Moje prace to
          szlachetne wydruki pigmentowe - każdy kolor jest zadrukiem, w związku
          z czym są bardzo delikatne na dotyk.{" "}
        </p>
        <p>
          Przy oprawie (i to dotyczy wszystkich typów grafik) warto umieścić pod
          plakatem dodatkową tekturę, którą można zakupić w każdym sklepie
          plastycznym. Odradzam również wieszania plakatów w pomieszczeniach o
          dużej wilgotności.
        </p>
      </div>
    </Layout>
  )
}

export default ShippInfoPage
